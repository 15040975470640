<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            grid:{
              top:70,
              left:70,
              bottom:50,
              right:120
            },
            toolbox: {
              right:120,
              top:10,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            legend: {
              show: true,
              right: 0,
              top: 40,
              itemGap: 20,
              itemWidth: 14,
              orient: "vertical",
              textStyle: { fontSize: 14, color: "#333333" },
            },
            xAxis: {
              name:val.xAxis.name,
              type: 'category',
              data: val.xAxis.data,
              nameLocation:'center',
              nameGap:25,
              axisLine:{
                onZero:false
              },
              splitLine:{
                show:false
              }
            },
            yAxis: {
              name:val.yAxis.name,
              type: 'value',
              nameLocation:'center',
              nameGap:45,
              splitLine:{
                show:false
              }
            },
            series: [],
          };
          for (let item of val.series){
            option.series.push({
              name:item.name,
              type: "line",
              data:item.data,
              symbol:'none',
              smooth:true
            })
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeLoad('loading2')
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
