<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            tooltip: {
              show:true
            },
            grid:{
              top:70,
              left:80,
              bottom:50,
              right:50
            },
            legend: {
              show: false,
            },
            xAxis: {
              type: 'category',
              name: val.xname,
              nameLocation:'center',
              nameGap:30,
              splitLine:{
                show:true
              },
              axisLine:{
                onZero:false
              },
              boundaryGap:false
            },
            yAxis: {
              name:val.yname,
              type: 'category',
              splitLine: {
                show:true
              },
            },
            series: [
              {
                // name: val.data1.name,
                type: "scatter",
                data:val.data,
                symbolSize:3,
              },
            ],
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 60px);
  }
</style>
