<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            color: ['#5470c6', '#91cc75', '#73c0de', '#fac858', '#ee6666', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            grid:{
              top:70,
              left:70,
              bottom:50,
              right:120
            },
            toolbox: {
              right:120,
              top:10,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            legend: {
              show: true,
              right: 0,
              top: 40,
              itemGap: 20,
              itemWidth: 14,
              orient: "vertical",
              textStyle: { fontSize: 14, color: "#333333" },
            },
            xAxis: {
              name:val.xAxis.name,
              type: 'category',
              data: val.xAxis.data,
              nameLocation:'center',
              nameGap:25,
              axisLine:{
                onZero:false
              },
              splitLine:{
                show:false
              }
            },
            yAxis: {
              name:val.yAxis.name,
              type: 'value',
              scale:true,
              boundaryGap: ["10%", "20%"],
              nameLocation:'center',
              nameGap:40,
              splitLine:{
                show:false
              }
            },
            series: [],
          };
          option.legend.selected = {};
          for (let item of val.series){
            option.legend.selected[item.type] = (item.type === "原始值" || item.type === "相对值" || item.type === "绝对值")
            option.series.push({
              name:item.type,
              type: "line",
              data:item.yaxis,
              smooth:true,
              lineStyle: {
                type: item.type.includes("阈值") ? "dotted" : "solid"
              }
            })
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeLoad('loading1')
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
