<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            title: {
              text:val.title,
              textStyle: {
                color:'#1A67D0',
                fontSize: 16
              }
            },
            tooltip: {
              trigger: 'item'
            },
            grid3D: {},
            xAxis3D: {
              type: 'value'
            },
            yAxis3D: {
              type: 'value'
            },
            zAxis3D: {
              type: 'value'
            },
            series: [
              {
                type: 'scatter3D',
                symbolSize: 5,
                tooltip:{
                  show:true
                },
                data:val.data
              },
                {
                  type: 'surface',
                  parametric: true,
                  color:'#ffffff1A',
                  tooltip:{
                    show:false
                  },
                  parametricEquation: {
                    u: {
                      min: -Math.PI,
                      max: Math.PI,
                      step: Math.PI / 20
                    },
                    v: {
                      min: 0,
                      max: Math.PI,
                      step: Math.PI / 20
                    },
                    x: function (u, v) {
                      return Math.sin(v) * Math.sin(u) * 1;
                    },
                    y: function (u, v) {
                      return Math.sin(v) * Math.cos(u) * 1;
                    },
                    z: function (u, v) {
                      return Math.cos(v) * 1;
                    }
                  }
                }
            ]
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeLoad('loading3')
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
