<template>
  <div ref="dom" class="charts"></div>
</template>

<script>
  import * as echarts from "echarts";
  import {on, off} from "@/libs/tools";

  export default {
    name: "echartsTest",
    data() {
      return {
        dom: null,
        option:null
      };
    },
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let that = this;
        this.$nextTick(() => {
          that.option = {
            toolbox: {
              right:30,
              top:10,
              feature: {
                restore:{
                  title: '刷新并还原'
                }
              }
            },
            tooltip: {
              show:false
            },
            backgroundColor: '#fff',
            xAxis3D: {
              type: 'value',
              max: val.xMax,
              min: val.xMin
            },
            yAxis3D: {
              type: 'value',
              max: val.yMax,
              min: val.yMin
            },
            zAxis3D: {
              type: 'value',
              max: val.zMax,
              min: val.zMin
            },
            grid3D: {
              show: false,
              boxWidth:500,
              boxHeight:500,
              viewControl: {
                projection: 'orthographic'
              }
            },
            series: [
              {
                type: 'line3D',
                data: val.bridgeBody,
                color: '#333',
                lineStyle: {
                  width: 4
                }
              }
            ]
          };
          this.getData(val)
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(that.option);
          on(window, "resize", this.resize);
          let self = this;
          this.dom.on("restore", function () {
            self.$emit("getData")
          });
        });
      },
      changeData(data){
        this.option.series = []
        this.option.series.push({
          type: 'line3D',
          data: data.bridgeBody,
          color: '#333',
          lineStyle: {
            width: 4
          }
        })
        this.getData(data)
      },
      getData(data){
        for (let i = 0; i < data.bridgePier.length; i++) {
          this.option.series.push({
            type: 'line3D',
            data: data.bridgePier[i],
            color: '#333',
            lineStyle: {
              width: 2
            }
          })
        }
        for (let i = 0; i < data.bridgeCable.length; i++) {
          let sign = data.bridgeCable[i].sign
          let color = '#666';
          switch (sign) {
            case 0:
              color = 'rgba(0,102,255)'
              break
            case 1:
              color = 'rgba(0,255,255)'
              break
            case 2:
              color = 'rgba(0,255,153)'
              break
            case 3:
              color = 'rgba(0,255,0)'
              break
            case 4:
              color = 'rgba(68,255,0)'
              break
            case 5:
              color = 'rgba(170,255,0)'
              break
            case 6:
              color = 'rgba(204,255,0)'
              break
            case 7:
              color = 'rgba(255,255,0)'
              break
            case 8:
              color = 'rgba(255,205,0)'
              break
            case 9:
              color = 'rgba(255,127,0)'
              break
            case 10:
              color = 'rgba(255,0,0)'
              break
            default:
              color = '#666'
          }
          this.option.series.push({
            type: 'line3D',
            data: data.bridgeCable[i].cable,
            color: color,
            lineStyle: {
              width: 1
            }
          })
        }
      }
    },
    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  }
</script>

<style scoped>
  .charts {
    width: 100%;
    height: 100%;
  }
</style>
